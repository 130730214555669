import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import PageTemplate from "gatsby-theme-hypercore/src/templates/page";
import { FONTS, COLORS } from "../../theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      main: {
        background: COLORS.paper,
      },
      // TODO JJ - wrap post body in div to apply styles more idiomatically?
      // (ie set a baseline width, with margin/padding etc rather than on each element)
      "main > p.MuiTypography-root.MuiTypography-body1, h2.MuiTypography-root.MuiTypography-h2, h3.MuiTypography-root.MuiTypography-h3, ul.MuiList-root, main > .download-button":
        {
          margin: "auto",
          maxWidth: 580,
          lineHeight: 1.44,
          padding: theme.spacing(1.5, 3),
        },
      "main > p.MuiTypography-root.MuiTypography-body1:first-of-type": {
        paddingTop: theme.spacing(8),
        [theme.breakpoints.up("md")]: {
          paddingTop: theme.spacing(12),
        },
      },
      // "main > p.MuiTypography-root.MuiTypography-body1:last-of-type": {
      "main > .download-button": {
        paddingBottom: theme.spacing(12),
      },
      ".gatsby-image-wrapper": {
        margin: `${theme.spacing(4)} auto`,
        // creates padding of 3 units from screen edge on either side
        // (setting padding just increases contained img's size)
        width: `calc(100% - ${theme.spacing(6)})`,
        maxWidth: 630,
      },
      ".wider-post-img .gatsby-image-wrapper": {
        maxWidth: 720,
      },
      "& .MuiTypography-body1": {
        fontSize: "16px",
        [theme.breakpoints.up("md")]: {
          fontSize: "18px",
        },
      },
      ".pull-quote": {
        margin: "auto",
        maxWidth: 630,
        padding: theme.spacing(2, 3),
        color: COLORS.primary,
        ...FONTS.KNOCKOUT["Lightweight"],
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(36),
        lineHeight: 1.1,
        letterSpacing: "0.05rem",
        "&::before": {
          content: `'“'`,
          position: "relative",
          marginLeft: "-12px",
          paddingRight: "0px",
          [theme.breakpoints.up("md")]: {
            marginLeft: "-14px",
            paddingRight: "0px",
          },
        },
        "&::after": {
          content: `'”'`,
          position: "relative",
          marginRight: "-12px",
          paddingLeft: "0px",
          [theme.breakpoints.up("md")]: {
            marginRight: "-14px",
            paddingLeft: "0px",
          },
        },
        [theme.breakpoints.up("sm")]: {
          fontSize: theme.typography.pxToRem(48),
          padding: theme.spacing(4, 3, 6),
        },
        [theme.breakpoints.up("md")]: {
          maxWidth: 780,
          fontSize: theme.typography.pxToRem(60),
          letterSpacing: "0.07rem",
        },
      },
      "ul.MuiList-root": {
        padding: theme.spacing(3, 6),
      },
    },
  })
);

export default function ResearchPaper({ pageContext }) {
  useStyles();

  // TODO JJ - a bit of a hack (sorry :\) result of using createPage (see gatsby-node)
  // Note that node.frontmatter.meta ends up on node.meta thanks to getMdxProps
  return <PageTemplate data={{ mdx: pageContext }} />;
}

// export const pageQuery = graphql`
//   query ResearchPaperQuery($id: String) {
//     mdx(id: { eq: $id }) {
//       id
//       body
//       frontmatter {
//         meta {
//           title
//           description
//           author
//           date
//           filePath
//           keywords
//           image {
//             childImageSharp {
//               gatsbyImageData(
//                 transformOptions: { fit: COVER, cropFocus: CENTER }
//                 width: 1200
//                 height: 630
//               )
//             }
//           }
//           isBlogPost
//         }
//         embeddedImages {
//           childImageSharp {
//             gatsbyImageData(layout: FULL_WIDTH)
//           }
//         }
//       }
//     }
//   }
// `;
